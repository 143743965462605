<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <top-title
      type="05"
      header="이용안내"
      body="웨비나 라이브 및 VOD 서비스 이용안내입니다."/>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">웨비나 & VOD</a></li>
            <li class="breadcrumb">이용안내</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <div class="section user_guide">
        <div class="view_link_icon_area">
          <a href="#">
            <img src="@/assets/images/icon_view_share.png" alt="">
          </a>
          <a href="#">
            <img src="@/assets/images/icon_view_print.png" alt="">
          </a>
          <a href="#">
            <img src="@/assets/images/icon_view_pdf.png" alt="">
          </a>
        </div>

        <div class="video_area">
          <iframe width="100%" height="auto" src="https://www.youtube.com/embed/SPMk-Urqsr4"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
        </div>

        <h1>User Guide Contents 1</h1>
        <ul>
          <li>The aim of this study is to evaluate the retention rates and reasons for discontinuation
            for seven biological disease-modifying antirheumatic drugs (bDMARDs) in a real-world
            setting of elderly patients (65 years of age or older) with rheumatoid arthritis (RA).
          </li>
          <li>The aim of this study is to evaluate the retention rates and reasons for discontinuation
            for seven biological disease-modifying antirheumatic drugs (bDMARDs) in a real-world
            setting of elderly patients (65 years of age or older) with rheumatoid arthritis (RA).
          </li>
          <li>The aim of this study is to evaluate the retention rates and reasons for discontinuation
            for seven biological disease-modifying antirheumatic drugs (bDMARDs) in a real-world
            setting of elderly patients (65 years of age or older) with rheumatoid arthritis
            (RA).The aim of this study is to evaluate the retention rates and reasons for
            discontinuation for seven biological disease-modifying antirheumatic drugs (bDMARDs) in
            a real-world setting of elderly patients (65 years of age or older) with rheumatoid
            arthritis (RA).</li>
        </ul>

        <h1>User Guide Contents 2</h1>
        <ul>
          <li>The aim of this study is to evaluate the retention rates and reasons for discontinuation
            for seven biological disease-modifying antirheumatic drugs (bDMARDs) in a real-world
            setting of elderly patients (65 years of age or older) with rheumatoid arthritis (RA).
          </li>
          <li>The aim of this study is to evaluate the retention rates and reasons for discontinuation
            for seven biological disease-modifying antirheumatic drugs (bDMARDs) in a real-world
            setting of elderly patients (65 years of age or older) with rheumatoid arthritis (RA).
          </li>
          <li>The aim of this study is to evaluate the retention rates and reasons for discontinuation
            for seven biological disease-modifying antirheumatic drugs (bDMARDs) in a real-world
            setting of elderly patients (65 years of age or older) with rheumatoid arthritis
            (RA).The aim of this study is to evaluate the retention rates and reasons for
            discontinuation for seven biological disease-modifying antirheumatic drugs (bDMARDs) in
            a real-world setting of elderly patients (65 years of age or older) with rheumatoid
            arthritis (RA).</li>
        </ul>

        <h1>User Guide Contents 3</h1>
        <ul>
          <li>The aim of this study is to evaluate the retention rates and reasons for discontinuation
            for seven biological disease-modifying antirheumatic drugs (bDMARDs) in a real-world
            setting of elderly patients (65 years of age or older) with rheumatoid arthritis (RA).
          </li>
          <li>The aim of this study is to evaluate the retention rates and reasons for discontinuation
            for seven biological disease-modifying antirheumatic drugs (bDMARDs) in a real-world
            setting of elderly patients (65 years of age or older) with rheumatoid arthritis (RA).
          </li>
          <li>The aim of this study is to evaluate the retention rates and reasons for discontinuation
            for seven biological disease-modifying antirheumatic drugs (bDMARDs) in a real-world
            setting of elderly patients (65 years of age or older) with rheumatoid arthritis
            (RA).The aim of this study is to evaluate the retention rates and reasons for
            discontinuation for seven biological disease-modifying antirheumatic drugs (bDMARDs) in
            a real-world setting of elderly patients (65 years of age or older) with rheumatoid
            arthritis (RA).</li>
        </ul>

      </div>
    </div>
  </main>
</template>

<script>
import TopTitle from "@/components/common/TopTitle";
export default {
  name: "UserGuide",
  components: {TopTitle}
}
</script>

<style scoped>

</style>
